@import "react-phone-number-input/style.css";

.PhoneInput--focus .PhoneInputInput:focus {
    outline: none;
}

.PhoneInputCountryIcon--border {
    box-shadow: none;
}

.PhoneInputInput {
    padding-left: 20px
}